import * as React from 'react';
import { PaletteMode } from '@mui/material';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import ToggleColorMode from '../ToggleColorMode';
import { FEATURED_URL } from '../../constants/CommonConstants';
import LoginPage from '../dashboard/user/LoginPage';
import LogoComponent from '../common/LogoComponent';
import { FeedbackTwoTone, LogoutTwoTone, QuestionAnswerTwoTone } from '@mui/icons-material';
import FeedbackQuery from './user/FeedbackQuery';
import { analyticsEvent } from '../../utils/CommonApiCalls';
import { ANA_FEEDBACK_QUERY_OPENED } from '../../constants/AnalyticsConstants';
import { logout } from './user/LoginLogout';

const logoStyle = {
  width: '140px',
  height: 'auto',
  cursor: 'pointer',
};

interface AppAppBarProps {
  mode: PaletteMode;
  toggleColorMode: () => void;
}

function DashboardAppBar({ mode, toggleColorMode }: AppAppBarProps) {
  const [open, setOpen] = React.useState(false);
  const [feedbackOpen, setFeedbackOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const toggleFeedback = (newOpen: boolean) => () => {
    if (newOpen) {
      analyticsEvent(ANA_FEEDBACK_QUERY_OPENED);
    }
    setFeedbackOpen(newOpen);
  };

  const goToShowcase = () => () => {
    window.location.href = FEATURED_URL;
  };

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: 'transparent',
          backgroundImage: 'none',
          mt: 0,
        }}
      >
        <Container maxWidth="lg">
          <Toolbar
            variant="regular"
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexShrink: 0,
              borderRadius: '9px',
              bgcolor:
                theme.palette.mode === 'light'
                ? 'rgba(155, 155, 155, 0.2)'
                : 'rgba(0, 0, 0, 0.4)',
              backdropFilter: 'blur(24px)',
              maxHeight: 40,
              border: '1px solid',
              borderColor: 'divider',
            })}
          >
            <LogoComponent/>
            <Box
              sx={{
                gap: 0.5,
                alignItems: 'center',
              }}
            >

             <Button
                variant="outlined"
                color= {mode === 'dark' ? "secondary" : 'primary'}
                aria-label="menu"
                onClick={goToShowcase()}
                sx={{ m:1, py: '5px', px:'1', fontSize: { xs: '8', sm: '15' }, fontWeight: 'bold' }}
              >
              <Box sx={{display: {xs:"none", sm:"flex"}}}>
               Featured Reports
              </Box>
              <Box sx={{display: {xs:"flex", sm:"none"}}}>
               Featured
              </Box>
              </Button>
            </Box>
            <Box
              sx={{
                mr:1,
                gap: 0.5,
                alignItems: 'center',
              }}
            >
              <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} />
            </Box>
            

            <Box
              sx={{
                mr:1,
                mt:.3,
                gap: 0.5,
                alignItems: 'center',
              }}            
            >
              <Button
                variant="text"
                color="primary"
                aria-label="menu"
                onClick={toggleFeedback(true)}
                sx={{ minWidth: '30px', p: '4px' }}
              >
                <QuestionAnswerTwoTone />
              </Button>
              <Drawer anchor="right" open={feedbackOpen} onClose={toggleFeedback(false)}>
                <Box
                  sx={{
                    minWidth: {xs:"80dvw", sm:'32dvw'},
                    backgroundColor: 'background.paper',
                    flexGrow: 1,
                    p: 0
                  }}
                >
                <FeedbackQuery />
                </Box>
              </Drawer>
            </Box>

            <Box>
              <Button
                variant="text"
                color="primary"
                aria-label="menu"
                onClick={logout}
                sx={{ minWidth: '30px', p: '4px' }}
              >
                <LogoutTwoTone fontSize="small" />
              </Button>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

export default DashboardAppBar;
import type {} from '@mui/material/themeCssVarsAugmentation';
import { ThemeOptions, alpha } from '@mui/material/styles';
import { grey, red } from '@mui/material/colors';
import { PaletteMode } from '@mui/material';

declare module '@mui/material/styles/createPalette' {
  interface ColorRange {
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
  }

  interface PaletteColor extends ColorRange {}
}

// export const brand = {
//   50: '#F94144',
//   100: '#F3722C',
//   200: '#F8961E',
//   300: '#F9844A',
//   400: '#F9C74F',
//   500: '#90BE6D',
//   600: '#43AA8B',
//   700: '#4D908E',
//   800: '#577590',  //p
//   900: '#277DA1 ',
// };

export const brand = {
  50: '#D9ED92',
  100: '#B5E48C',
  200: '#99D98C',
  300: '#76C893',
  400: '#52B69A',
  500: '#34A0A4',
  600: '#34A0A4',
  700: '#168AAD',
  800: '#168AAD',  //p
  900: '#1A759F ',
};

export const secondary = {
  50: '#fdf4e1',
  100: '#fbe2b5',
  200: '#f8d084',
  300: '#f6bd53',
  400: '#f5af2f',
  500: '#f5a113',
  600: '#f1950e',
  700: '#eb8609',
  800: '#e57704',  //p
  900: '#dc5f00 ',
};

// export const secondary = {
//   50: '#d16ba5',
//   100: '#c777b9',
//   200: '#ba83ca',
//   300: '#aa8fd8',
//   400: '#9a9ae1',
//   500: '#8aa7ec',
//   600: '#69bff8',
//   700: '#52cffe',
//   800: '#46eefa',  //p
//   900: '#5ffbf1',
// };

// export const brand = {
//   50: '#D9ED92',
//   100: '#B5E48C',
//   200: '#99D98C',
//   300: '#76C893',
//   400: '#52B69A',
//   500: '#34A0A4',
//   600: '#168AAD',
//   700: '#1A759F',
//   800: '#1E6091',  //p
//   900: '#184E77 ',
// };

// export const brand = {
//   50: '#fdf4e1',
//   100: '#fbe2b5',
//   200: '#f8d084',
//   300: '#f6bd53',
//   400: '#f5af2f',
//   500: '#f5a113',
//   600: '#f1950e',
//   700: '#eb8609',
//   800: '#e57704',  //p
//   900: '#dc5f00 ',
// };


// export const secondary = {
//   50: '#e5f0f1',
//   100: '#bddadc',
//   200: '#96c3c6',
//   300: '#76abae',
//   400: '#669a9b',
//   500: '#54898a',
//   600: '#4f7d7d',
//   700: '#486d6d',  //p
//   800: '#425e5d',
//   900: '#344241',
// };
// export const secondary = {
//   50: '#e3f3fe',
//   100: '#bbe1fe',
//   200: '#90cefe',
//   300: '#62bbfd',
//   400: '#3cacfe',
//   500: '#099dfd',
//   600: '#068fef',
//   700: '#007ddc',  //p
//   800: '#006bca',
//   900: '#004dab',
// };

// export const gray = {
//   50: '#fafafa',
//   100: '#f5f5f5',
//   200: '#eeeeee',
//   300: '#e0e0e0',
//   400: '#bdbdbd',
//   500: '#9e9e9e',
//   600: '#757575',
//   700: '#616161',
//   800: '#424242',
//   900: '#212121',
// };


//Was using this
// export const gray = {
//   50: '#f4f8fa',
//   100: '#ebeef1',
//   200: '#dfe2e5',
//   300: '#cdd0d2',
//   400: '#a8abad',
//   500: '#878a8c',
//   600: '#606365',
//   700: '#4d5052',
//   800: '#2f3234',
//   900: '#0f1214',
// };

export const gray = {
  50: '#f3f7ff',
  100: '#eaeef5',
  200: '#dde1e9',
  300: '#cbcfd6',
  400: '#a6aab1',
  500: '#858990',
  600: '#5e6268',
  700: '#4b4f55',
  800: '#121820',
  900: '#0d1117',
};

export const green = {
  50: '#e9f4ea',
  100: '#c9e4cb',
  200: '#a8d3aa',
  300: '#86c389',
  400: '#6db671',
  500: '#56aa59',
  600: '#4d9b50',
  700: '#438945',  //p
  800: '#3a783c',
  900: '#2a592b',
};

// another option #cd5c5c

const getDesignTokens = (mode: PaletteMode) => ({
  palette: {
    mode,
    primary: {
      light: brand[200],
      main: brand[500],
      dark: brand[800],
      contrastText: gray[50],   //contrastText: brand[50],
      ...(mode === 'dark' && {
        contrastText: gray[100],  //contrastText: brand[100],
        light: brand[300],
        main: brand[400],
        dark: brand[800],
      }),
    },
    secondary: {
      light: secondary[300],
      main: secondary[500],
      dark: secondary[800],
      ...(mode === 'dark' && {
        light: secondary[400],
        main: secondary[500],
        dark: secondary[900],
      }),
    },
    warning: {
      main: '#F7B538',
      dark: '#F79F00',
      ...(mode === 'dark' && { main: '#F7B538', dark: '#F79F00' }),
    },
    error: {
      light: red[50],
      main: red[500],
      dark: red[700],
      ...(mode === 'dark' && { light: '#D32F2F', main: '#D32F2F', dark: '#B22A2A' }),
    },
    success: {
      light: green[300],
      main: green[400],
      dark: green[800],
      ...(mode === 'dark' && {
        light: green[400],
        main: green[500],
        dark: green[700],
      }),
    },
    grey: {
      50: gray[50],
      100: gray[100],
      200: gray[200],
      300: gray[300],
      400: gray[400],
      500: gray[500],
      600: gray[600],
      700: gray[700],
      800: gray[800],
      900: gray[900],
    },
    divider: mode === 'dark' ? alpha(gray[600], 0.3) : alpha(gray[300], 0.5),
    background: {
      default: '#eee',  //was #fff
      paper: gray[50],
      ...(mode === 'dark' && { default: gray[900], paper: gray[900] }),   // paper: gray[800]
    },
    text: {
      primary: gray[800],
      secondary: gray[600],
      ...(mode === 'dark' && { primary: gray[200], secondary: gray[300] }),  // gray[50]
    },
    action: {
      selected: `${alpha(brand[200], 0.2)}`,
      ...(mode === 'dark' && {
        selected: alpha(brand[800], 0.2),
      }),
    },    
  },
  typography: {
    // fontFamily: ['"Source Sans Pro"', "sans-serif"].join(','),
    fontFamily: "monospace",
    webkitFontSmoothing: "antialiased",
    textRendering: 'optimizeLegibility',
    fontWeight: 400,
    // fontSize: 16,
    // fontOpticalSizing: 'auto',
    fontStyle: 'normal',
    letterSpacing: '5px',
    h1: {
      // fontFamily: "monospace",
      // fontFamily: ["Barlow Semi Condensed", "sans-serif"].join(','),
      fontSize: 60,
      fontWeight: 600,
      lineHeight: 78 / 70,
      letterSpacing: -0.2,
    },
    h2: {
      // fontFamily: "monospace",
      // fontFamily: ["Barlow Semi Condensed", "sans-serif"].join(','),
      fontSize: 48,
      fontWeight: 600,
      lineHeight: 1.2,
    },
    h3: {
      // fontFamily: "monospace",
      // fontFamily: ["Barlow Semi Condensed", "sans-serif"].join(','),
      fontSize: 42,
      lineHeight: 1.2,
    },
    h4: {
      // fontFamily: "monospace",
      // fontFamily: ["Barlow Semi Condensed", "sans-serif"].join(','),
      fontSize: 36,
      fontWeight: 500,
      lineHeight: 1.5,
    },
    h5: {
      // fontFamily: "monospace",
      // fontFamily: ["Barlow Semi Condensed", "sans-serif"].join(','),
      fontSize: 20,
      fontWeight: 600,
    },
    h6: {
      // fontFamily: "monospace",
      // fontFamily: ["Barlow Semi Condensed", "sans-serif"].join(','),
      fontSize: 18,
    },
    subtitle1: {
      fontFamily: ["Barlow Semi Condensed", "sans-serif"].join(','),
      // fontFamily: ['"Source Sans Pro"', "sans-serif"].join(','),
      fontSize: 36,
    },
    subtitle2: {
      fontFamily: ["Barlow Semi Condensed", "sans-serif"].join(','),
      // fontFamily: ['"Source Sans Pro"', "sans-serif"].join(','),
      fontSize: 20,
      letterSpacing: "0.01em"
    },
    body1: {
      // fontFamily: ["Barlow Semi Condensed", "sans-serif"].join(','),
    fontFamily: ['"Source Sans Pro"', "sans-serif"].join(','),
    // letterSpacing: .5,
      fontWeight: 400,
      fontSize: 16,
      // lineHeight: 1.5
    },
    body2: {
      // fontFamily: ["Barlow Semi Condensed", "sans-serif"].join(','),
      fontFamily: ['"Source Sans Pro"', "sans-serif"].join(','),
      fontWeight: 400,
      fontSize: 14,
      // lineHeight: 2
    },
    caption: {
      fontFamily: ['"Source Sans Pro"', "sans-serif"].join(','),
      fontWeight: 400,
      fontSize: 12,
    },
  },
});

export default function getLPTheme(mode: PaletteMode): ThemeOptions {
  return {
    ...getDesignTokens(mode)
  };
}
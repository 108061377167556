export const APP_NAME = "researchStack"
export const APP_LOGO_FIRST_NAME = "research"
export const APP_LOGO_LAST_NAME = "Stack"
export const APP_LOGO_SUFFIX = ".ai"
export const APP_NAME_TAG_LINE = "ResearchStack.ai - Unlocking Deeper Insights"
export const APP_DESCRIPTION = "Need well-researched, tailored information without sacrificing your valuable time? We got you covered! Meet ResearchStack, your dedicated AI-powered research assistant."
export const APP_IMAGE_URL = "https://www.researchstack.ai/images/og_image.png"
export const APP_KEYWORDS = "researchstack.ai, ai research stack, research stack ai, perplexity.ai competitor, perplexity competitor, perplexity vs researchstack, information discovery, information research, ai research assistant, automated research assistant";
export const APP_SIGN_IN_URL = "sign-in";
//dev
// export const API_BASE_URL = "http://localhost:8080"
// export const UI_BASE_URL = "http://localhost:3000"
//prod
export const API_BASE_URL = "https://researchstack.ai"
export const UI_BASE_URL = "https://researchstack.ai"
export const LOGO = UI_BASE_URL +"/g-icon.png"
export const EARLY_ACCESS_REG = "/api/user/public/register/early"
export const API_USER_LOGIN = "/api/user/login"
export const API_USER_LOGIN_CHECK = "/api/user/check";
export const API_USER_QUOTAS = "/api/user/quotas"
export const API_USER_DETAILS = "/api/user/details"
export const DASHBOARD_SLUG = "/dashboard"
export const ABOUT_SLUG = "/about"
export const FEATURED_URL = "/featured/reports"
export const FEATURED_VIEW_URL = "/featured/report/view"
export const DASHBOARD_URL = UI_BASE_URL + DASHBOARD_SLUG
export const UI_LOGIN_URL = UI_BASE_URL + "/login"
export const API_LAUNCH_RESEARCH = "/api/research/launch"
export const API_VIEW_RESEARCH = "/api/research/view?"
export const UI_VIEW_RESEARCH = "/research/view"
export const RESEARCH_REPORT_FONT = "source-serif-pro, Georgia, Cambria, Times New Roman, Times, serif"
export const API_DELETE_RESEARCH = "/api/research/delete?id="
export const SUCCESS_MESSAGE = "success";
export const API_COMMUNICATE_SAY = "/api/communication/public/say"
export const COMMUNICATE_FEEDBACK = "feedback"
export const COMMUNICATE_QUERY = "query"
export const STATUS_COMPLETED = "completed"
export const API_ANALYTICS_LOG = "/api/collect/public/collect"
export const YELLOW_COLOR = "#F9C74F"
import * as React from 'react';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Alert, Button, Card, CardContent, Container, PaletteMode, Stack, Typography } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import LandingPageAppBar from '../../pages/LandingPageAppBar';
import getLPTheme from '../../getLPTheme';
import { useCookies } from 'react-cookie';
import UserData from '../common/models/UserData';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import { showcase } from './ShowcaseData';
import { useParams } from 'react-router-dom';
import { analyticsLog } from '../../utils/CommonApiCalls';
import { ANA_FEATURED_REPORT_OPENED, ANA_FEATURED_REPORT_READ_PER_ } from '../../constants/AnalyticsConstants';
import { ArrowDropDownCircleOutlined } from '@mui/icons-material';
import SEOBasic from '../../utils/SEOBasic';
import { RESEARCH_REPORT_FONT } from '../../constants/CommonConstants';
import ReportFontStyle from '../common/style/ReportFontStyle';
import { isEmptyString } from '../../utils/StringUtils';
import { getPreferredScheme } from '../../utils/CommonFunctions';

export default function FeaturedPageView() {
  const [title, setTitle] = React.useState('');
  const [requirement, setRequirement] = React.useState('');
  const [slug, setSlug] = React.useState('');
  const [note, setNote] = React.useState('');
  const [details, setDetails] = React.useState('');
  const [snippet, setSnippet] = React.useState('');
  const [mode, setMode] = React.useState<PaletteMode>(getPreferredScheme());
  const LPtheme = createTheme(getLPTheme(mode));
  const [cookies, setCookie] = useCookies(['user']);
  const [currentScrollPercentage, setCurrentScrollPercentage] = React.useState(0);
  const [maxScrollPercentageReached, setMaxScrollPercentageReached] = React.useState(0);
  const [user, setUser] = React.useState<UserData>({
    name: '',
    email: '',
    preferences: {
      darkMode: mode === 'dark'
    },
  });

  const toggleColorMode = () => {
    handleSaveUser({ ...user, preferences: { ...user.preferences, darkMode: mode === 'light' } });
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  const handleLoadUser = () => {
    const retrievedUser: UserData = cookies.user ? (cookies.user) : null;
    if (retrievedUser) {
      setMode(retrievedUser.preferences.darkMode ? 'dark' : 'light');
      setUser(retrievedUser);
    }
    const queryParams = new URLSearchParams(window.location.search);
    const value = queryParams.get('title');
    showcase.map((row) => {
      if (row.slug == value){
        analyticsLog(ANA_FEATURED_REPORT_READ_PER_ + maxScrollPercentageReached, value)
        setSlug(row.slug)
        setNote(row.note ? row.note : "")
        setRequirement(row.requirement)
        setTitle(row.title)
        setDetails(row.detail)
        setSnippet(row.snippet)
      }
    });
  };

  async function handleScroll() {
    const windowHeight = window.innerHeight;
    const fullHeight = document.documentElement.scrollHeight;
    const currentPosition = window.scrollY;

    var scrollPercentage = (currentPosition / (fullHeight - windowHeight)) * 100;
    scrollPercentage = Math.ceil(scrollPercentage);
    if (scrollPercentage > currentScrollPercentage && scrollPercentage % 10 == 0) {
      await setCurrentScrollPercentage(scrollPercentage);      
    }
  };

  React.useEffect(() => {
    if (currentScrollPercentage > maxScrollPercentageReached) {
      setMaxScrollPercentageReached(currentScrollPercentage);      
    }
  }, [currentScrollPercentage]);

  React.useEffect(() => {
    if (maxScrollPercentageReached > 0) {
      analyticsLog(ANA_FEATURED_REPORT_READ_PER_ + maxScrollPercentageReached, slug)
    }
  }, [maxScrollPercentageReached]);

  React.useEffect(() => {
    handleLoadUser();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleSaveUser = (user: UserData) => {
    setCookie('user', JSON.stringify(user), {
      path: '/',
      expires: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000), // Expires in 15 day
      secure: true, // Only sent over HTTPS for enhanced security (recommended)
      sameSite: 'strict', // Mitigates CSRF attacks (recommended)
    });
  };

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <SEOBasic title={title} description={snippet} imageUrl=""/>
      <LandingPageAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Box sx={{ bgcolor: 'background.default' }}>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: '10%' ,
          pb: '2%',
        }}
      >
        {/* <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}> */}
          <Card sx={{ maxWidth: { xs: '100%', sm: '75%' }, maxHeight: { xs: '100%', sm: '85%' }, p: { xs: 0, sm: 5 } }}>
            <CardContent sx={{mx: { xs: .2, sm: 4 ,}}}>
            
              {/* <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                {row.title}
              </Typography> */}
              {/* fontSize: {xs: 25,sm : 45} */}
              <Typography variant="h4" color='secondary.main' sx={{ mb: 3, mt: 2 }} >
                <ReportFontStyle>{title}</ReportFontStyle>
              </Typography>
              <Typography variant="h6" color='secondary.main' sx={{ mb: 3, mt: 2 }} >{note}</Typography>
              <Accordion variant='outlined'>
                <AccordionSummary
                  expandIcon={<ArrowDropDownCircleOutlined />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography sx={{px:1}}>Given Requirement</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{px:1, pb:1, fontSize: { xs: 13, sm: 18 }} }>
                    <ReportFontStyle>
                      {requirement}
                    </ReportFontStyle>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              {/* <Typography sx={{ mb: 1.5 }} color="text.secondary">
                adjective
              </Typography> */}
              <Typography variant="body1" sx={{ mx: { xs: .2, sm: .2 }, fontSize: { xs: 13, sm: 18 }}}>
                <ReportFontStyle><div dangerouslySetInnerHTML={{__html: details}}></div></ReportFontStyle>
              </Typography>
            </CardContent>
            {/* <CardActions sx={{mx:1}}>
              <Button size="small" onClick={() => handleView(row.id, row.title)}>Read Full report</Button>
            </CardActions> */}
          </Card>    
            {/* </Stack> */}
            </Container>
        <Divider />
      </Box>
    </ThemeProvider>
  );
}
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import  { EARLY_ACCESS_REG, FEATURED_URL } from "../constants/CommonConstants";
import { Alert, Backdrop, Chip, CircularProgress, Link, Snackbar } from '@mui/material';
import { api } from '../utils/Axios';
import { analyticsLog } from '../utils/CommonApiCalls';
import { ANA_EARLY_ACCESS_EMAIL_SUBMITTED } from '../constants/AnalyticsConstants';
import { RTextField } from '../components/common/style/RTextField';
import { GridArrowDownwardIcon } from '@mui/x-data-grid';
import { ArrowDownward, KeyboardArrowDown } from '@mui/icons-material';

export default function Hero() {
  const [email, setEmail] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [openBackdrop, setBackdrop] = React.useState(false);

  function handleBackdropClose () {
    setBackdrop(false);
  }

  function handleBackdropOpen () {
    setBackdrop(true);
  }

  function handleClose () {
    setOpen(false);
  }

  const goToShowcase = () => () => {
    window.location.href = FEATURED_URL;
  };
  
  function handleGetEarlyAccess () {
    const data = {
      email: email
    }
    handleBackdropOpen();
    api.post(EARLY_ACCESS_REG, data)
    .then(function (response) {
      if (response.data == "success") {
        analyticsLog(ANA_EARLY_ACCESS_EMAIL_SUBMITTED, email);
        setMessage("Thanks for sharing your email! We will soon get in-touch")
        setOpen(true);
        setEmail("");
      } else {
        setMessage(response.data)
        setOpen(true)
      }
      handleBackdropClose();
    })
    .catch(function (error) {
      setMessage("We are experiencing some issue at the moment, Please try again later.")
      setOpen(true)
      handleBackdropClose();
    });
  }

  return (
    <Box
      sx={(theme) => ({
        width: '100%',
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'primary.main' // 'linear-gradient(180deg, #CEE5FD, #FFF)'
            : 'grey.main', //`linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        onClick={handleBackdropClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar open={open} onClose={handleClose} anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}>
        <Alert
          onClose={handleClose}
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      </Snackbar>

      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: '25%', sm: '10%' } ,
          pb: '2%',
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
          <Typography
            component="h1"
            variant="h1"
            sx={{
              display: '',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'center',
              textAlign: 'center',
              fontSize: 'clamp(2rem, 9vw, 5rem)',     
              color: (theme) =>
                theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
            }}
          >
            {/* Swap Research Hours for &nbsp; */}
            Unlock 
            {/* Meaningful Information */}
            {/* Information That Truly Matters */}
            {/* From Clutter */}
            </Typography>
            <Typography
              component="span"
              variant="h1"
              sx={{
                flexDirection: { xs: 'column', md: 'row' },
                alignSelf: 'center',
                textAlign: 'center',
                fontSize: 'clamp(3rem, 10vw, 4rem)',
                // fontWeight: '400',
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'secondary.main' : 'secondary.light',
              }}
            >
               Deeper Insights
              {/* Research assistant */}
              {/* Information Discovery */}
               {/* Delivered. */}
              {/* To Clarity */}
              {/* Decision Power */}
            </Typography>
          {/* <Typography>Is research a time-consuming black hole?  You're not alone.</Typography> */}
          <Typography
            variant='subtitle2'
            textAlign="center"
            color="text.secondary"
            sx={{ alignSelf: 'center', width: { sm: '100%', md: '82%', marginTop: 15 } }}
          >
            Need well-researched, tailored information without sacrificing your valuable time? We got you covered! 
            </Typography>
            <Typography
            variant='subtitle2'
            textAlign="center"
            color="text.secondary"
            sx={{ alignSelf: 'center', width: { sm: '100%', md: '82%', marginTop: 10 } }}
          >
           Meet ResearchStack, your dedicated AI-powered research assistant.
            {/* The most capable research assistant that works 24/7 to gathering data so that you can make <b>better decision faster.</b> */}
            {/* The most capable information discovery platform that works 24/7 to help you make <b>better decision faster.</b> */}
            {/* Leverage relevant information and focus on making decision */}
          </Typography>
          {/* <Typography
            textAlign="center"
            color="secondary"
            sx={{ alignSelf: 'center', width: { sm: '100%', md: '80%', marginTop: '0' } }}
          > */}
          {/* <Box textAlign="center" sx={{ alignSelf: 'center', width: { sm: '100%', md: '80%', marginTop: '0' } }}>
            <Button variant="outlined" color={'primary'} onClick={goToShowcase()}>
              Checkout Featured Reports
            </Button>
          </Box> */}
          {/* </Typography> */}
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignSelf="center"
            spacing={1}
            useFlexGap
            sx={{ pt: 1, minWidth: { xs: '100%', sm: '60%' } }}
          >
            <RTextField
              id="email"
              // hiddenLabel
              type='email'
              size="small"
              // variant="outlined"
              aria-label="Enter your email address"
              placeholder="Your email address"
              onChange={(event) => setEmail(event.target.value)}
              value={email}
              sx={{minWidth: { xs: '100%', sm: '50%' }}}
            />
            <Button variant="contained" color='secondary' sx={{minWidth : { xs: '100%', sm: '50%'}, fontSize: '0.975rem' , backgroundImage: "linear-gradient(to right top, #f5af2f, #eabe19, #dacd00, #c5dc00, #a8eb12);"}} onClick={handleGetEarlyAccess}>
              Get Early Access
            </Button>
          </Stack>
          {/* <Typography variant="caption" textAlign="center" sx={{ opacity: 0.8 }}>
          Join the community of early adopters or &nbsp;
            <Link sx={{cursor: "pointer"}} color={'primary'} onClick={goToShowcase()}>
               Checkout Featured Reports
            </Link>
          </Typography> */}
          
          <Typography variant="body2" textAlign="center" sx={{ opacity: 0.8 }}>
          <Chip icon={<ArrowDownward />} label="Compare with ChatGPT / Perplexity.ai" variant="filled" />
            {/* Compare ChatGPT / Perplexity.ai<KeyboardArrowDown/> */}
          </Typography>
        </Stack>
        
      </Container>
    </Box>
  );
}
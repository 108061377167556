import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { List, ListItem, ListItemIcon, ListItemText, ListSubheader, Switch, Typography } from '@mui/material';
import { Person2, Person2TwoTone, Person3TwoTone, PersonTwoTone } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

export default function BasicTable() {

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[50],
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  }
}));
  return (
    <Paper elevation={0}>
    <TableContainer sx={{ minWidth: 650, }} component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <StyledTableCell sx={{width: "50%", }}><Typography variant='subtitle2'>Chatgpt/Perplexity etc.</Typography></StyledTableCell>
            <StyledTableCell sx={{width: "50%", }}><Typography variant='subtitle2'>ResearchStack</Typography></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={"1"}>
            <TableCell component="td" scope="row" sx={{verticalAlign: 'top', pt: 2, px: 3}}>
            <Typography variant='body1' alignContent='start'>Build to engage in <b>User-Driven</b> conversations</Typography>
            </TableCell>
            <TableCell component="td" sx={{verticalAlign: 'top', pt: 2, px: 3}}>
            <Typography variant='body1'>Build to work <b>Independently</b> on given requirement</Typography>
            </TableCell>
          </TableRow>
          <TableRow key={"2"}>
            <TableCell component="td" scope="row" sx={{verticalAlign: 'top', pt: 0, px: 3}}>
            <Typography variant='body1'>You get "<b>Quick Answer</b>"</Typography>
            </TableCell>
            <TableCell component="td" sx={{verticalAlign: 'top', pt: 0, px: 3}}>
            <Typography variant='body1'>You get "<b>Well Researched Comprehensive Information</b>"</Typography>
            </TableCell>
          </TableRow>
          <TableRow key={"3"}>
            <TableCell component="td" scope="row" sx={{verticalAlign: 'top', pt: 0, px: 3}}>
            <Typography variant='body1'>LLM’s are Prone to Hallucinations and make up facts</Typography>
            </TableCell>
            <TableCell component="td" sx={{verticalAlign: 'top', pt: 0, px: 3}}>
            <Typography variant='body1'>Algorithms monitor the quality of information being shared with User</Typography>
            </TableCell>
          </TableRow>
          <TableRow key={"4"}>
            <TableCell component="td" scope="row" sx={{verticalAlign: 'top', pb: 3, px: 3}}>
                    <Typography variant='subtitle2'><u>Current State</u></Typography> 
                    <Typography variant='body1'>You need to conduct the research, which limits you to single topic at a time</Typography>
            </TableCell>
            <TableCell component="td" scope="row" sx={{verticalAlign: 'top', pb: 3, px: 3}}>
                    <Typography variant='subtitle2'><u>Current State</u></Typography> 
                    <Typography variant='body1'>Run as many researches as you want in parallel, without sacrificing your valuable time and effort</Typography>                    
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    </Paper>
  );
}

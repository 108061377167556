import * as React from 'react';
import { PaletteMode } from '@mui/material';
import { useCookies } from 'react-cookie';
import UserData from '../components/common/models/UserData';

export const getPreferredScheme = () => window?.matchMedia?.('(prefers-color-scheme:dark)')?.matches ? 'dark' : 'light';
export const isDarkScheme = () => window?.matchMedia?.('(prefers-color-scheme:dark)')?.matches;

// export const toggleColorModeChange = () => {
//     const [cookies, setCookie] = useCookies(['user']);
//     const [mode, setMode] = React.useState<PaletteMode>('dark');
//     const [user, setUser] = React.useState<UserData>();

//     const retrievedUser: UserData = cookies.user ? (cookies.user) : null;
//     if (retrievedUser) {
//       retrievedUser.preferences.darkMode = ( mode === 'light');
//       setUser(retrievedUser);
//       setCookie('user', JSON.stringify(retrievedUser), {
//         path: '/',
//         expires: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000), // Expires in 15 day
//         secure: true, // Only sent over HTTPS for enhanced security (recommended)
//         sameSite: 'strict', // Mitigates CSRF attacks (recommended)
//       });
//     }
//     setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
//   };
import * as React from 'react';
import LandingPage from './LandingPage';
import {Routes, Route} from 'react-router-dom';
import Dashboard from './Dashboard';
import LoginPage from './components/dashboard/user/LoginPage';
import NoMatch from './components/otherpages/NoMatch';
import { DASHBOARD_SLUG, FEATURED_URL, FEATURED_VIEW_URL, UI_VIEW_RESEARCH } from './constants/CommonConstants';
import ResearchViewPage from './components/dashboard/research/ResearchView';
import FeaturedListView from './components/otherpages/FeaturedListPage';
import FeaturedPageView from './components/otherpages/FeaturedPageView';
import { useCookies } from 'react-cookie';
import UserData from './components/common/models/UserData';
import { HelmetProvider } from 'react-helmet-async';
import { getPreferredScheme, isDarkScheme } from './utils/CommonFunctions';

export default function App() {
  const [cookies, setCookie] = useCookies(['user']);
  const [user, setUser] = React.useState<UserData>({
    name: '',
    email: '',
    preferences: {
      darkMode: isDarkScheme(),  // keep default as dark
    },
  });

  const handleSaveUser = (user: UserData) => {
    setCookie('user', JSON.stringify(user), {
      path: '/',
      expires: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000), // Expires in 15 day
      secure: true, // Only sent over HTTPS for enhanced security (recommended)
      sameSite: 'strict', // Mitigates CSRF attacks (recommended)
    });
  };

  const handleLoadUser = () => {
    const retrievedUser: UserData = cookies.user ? (cookies.user) : null;
    if (retrievedUser == null) {
      handleSaveUser(user);
    }
  };

  React.useEffect(() => {
    handleLoadUser();
  }, []);

  return (
    <HelmetProvider>
    <Routes>
      <Route index path="/" element={<LandingPage/>}/>
      <Route path="/login" element={<LoginPage/>}/>
      <Route path={DASHBOARD_SLUG} element={<Dashboard/>}/>
      <Route path={DASHBOARD_SLUG + UI_VIEW_RESEARCH} element={<ResearchViewPage/>}/>
      <Route path={FEATURED_URL} element={<FeaturedListView/>}/>
      <Route path={FEATURED_VIEW_URL} element={<FeaturedPageView/>}/>
      {/* <Route path="/sign-in" element={<SignIn/>}/>
      <Route path="/dashboard" element={<Dashboard/>}/> */}
      <Route path="*" element={<NoMatch />} />
    </Routes>
    </HelmetProvider>
  );
}

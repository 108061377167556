export const ANA_SUCCESS_MESSAGE = "success";
export const ANA_LANDING_PAGE_LOADED = "LANDING_PAGE_LOADED";
export const ANA_EARLY_ACCESS_EMAIL_SUBMITTED = "EARLY_ACCESS_EMAIL_SUBMITTED";
export const ANA_FEEDBACK_QUERY_OPENED = "FEEDBACK_QUERY_OPENED";
export const ANA_FEEDBACK_QUERY_SUBMITTED = "FEEDBACK_QUERY_SUBMITTED";
export const ANA_FEATURED_REPORTS_LIST_LOADED = "FEATURED_REPORTS_LIST_LOADED";
export const ANA_FEATURED_REPORT_OPENED = "FEATURED_REPORT_OPENED";
export const ANA_FEATURED_REPORT_READ_PER_ = "FEATURED_REPORT_READ_PER_";
export const ANA_PAGE_READ_PER_ = "PAGE_READ_PER_";
export const ANA_RESEARCH_REPORT_READ_PER_ = "RESEARCH_REPORT_READ_PER_";
export const ANA_FEATURED_REPORT_READ_RATING = "FEATURED_REPORT_READ_RATING";
export const ANA_RESEARCH_REPORT_READ_RATING = "RESEARCH_REPORT_READ_RATING";
export const ANA_USER_LOGGED_IN = "USER_LOGGED_IN";

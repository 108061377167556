import React from 'react';
import { Helmet } from 'react-helmet-async';
import { isEmptyString } from './StringUtils';

export default function SEOBasic({ title, description, imageUrl, keywords } : 
    {title: string, description?: string, imageUrl?: string, keywords?: string}) {

        React.useEffect(() => {
            (document.querySelector("meta[property='og:title']") || {remove:()=>0}).remove();
            (document.querySelector("meta[property='og:type']") || {remove:()=>0}).remove();
            (document.querySelector("meta[property='og:description']") || {remove:()=>0}).remove();
            (document.querySelector("meta[property='og:image']") || {remove:()=>0}).remove();
            (document.querySelector("meta[property='og:url']") || {remove:()=>0}).remove();
            
            (document.querySelector("meta[name='description']") || {remove:()=>0}).remove();
            (document.querySelector("meta[name='keywords']") || {remove:()=>0}).remove();
            
            (document.querySelector("meta[name='twitter:title']") || {remove:()=>0}).remove();
            (document.querySelector("meta[name='twitter:description']") || {remove:()=>0}).remove();
        }, []); 
        
    return (
        <Helmet>
            {description = (!isEmptyString(description)) ? description : title}
            { /* Standard metadata tags */}
            <title>{title}</title>
            <meta name='description' content={description} />
            {(keywords) ? (!isEmptyString(keywords)) ? <meta name='keywords' content={keywords} /> : "" : ""}
            
            { /* End standard metadata tags */}
            { /* Facebook tags */}
            <meta property="og:type" content={"website"} />
            <meta property="og:title" content={title} />
            <meta property="og:url" content={window.location.href} />
            {(imageUrl) ? (!isEmptyString(imageUrl)) ? <meta property="og:image" content={imageUrl} /> : "" : ""}
            <meta property="og:description" content={description} />
            { /* End Facebook tags */}
            { /* Twitter tags */}
            <meta name="twitter:card" content={"website"} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            { /* End Twitter tags */}
        </Helmet>
    )
}
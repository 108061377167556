import * as React from 'react';
import { PaletteMode } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import LandingPageAppBar from './pages/LandingPageAppBar';
import getLPTheme from './getLPTheme';
import Hero from './pages/Hero';
import { useCookies } from 'react-cookie';
import UserData from './components/common/models/UserData';
import { analyticsEvent } from './utils/CommonApiCalls';
import { ANA_LANDING_PAGE_LOADED } from './constants/AnalyticsConstants';
import { Helmet } from 'react-helmet-async';
import SEOBasic from './utils/SEOBasic';
import { APP_DESCRIPTION, APP_IMAGE_URL, APP_KEYWORDS, APP_NAME, APP_NAME_TAG_LINE } from './constants/CommonConstants';
import SEODetailed from './utils/SEODetailed';
import LandingPageBody from './pages/LandingPageBody';
import PageScrollTracker from './utils/PageScrollTracker';
import FeedbackComponent from './utils/FeedbackComponent';
import { getPreferredScheme } from './utils/CommonFunctions';

export default function LandingPage() {
  const [mode, setMode] = React.useState<PaletteMode>(getPreferredScheme());
  const LPtheme = createTheme(getLPTheme(mode));
  const [cookies, setCookie] = useCookies(['user']);
  const [user, setUser] = React.useState<UserData>({
    name: '',
    email: '',
    preferences: {
      darkMode: mode === 'dark'
    },
  });

  const toggleColorMode = () => {
    handleSaveUser({ ...user, preferences: { ...user.preferences, darkMode: mode === 'light' } });
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  const handleLoadUser = () => {
    const retrievedUser: UserData = cookies.user ? (cookies.user) : null;
    if (retrievedUser) {
      setMode(retrievedUser.preferences.darkMode ? 'dark' : 'light');
      setUser(retrievedUser);
    } else {
      handleSaveUser(user);
    }
  };

  React.useEffect(() => {
    const retrievedUser: UserData = cookies.user ? (cookies.user) : null;
    handleLoadUser();
    analyticsEvent(ANA_LANDING_PAGE_LOADED);
  }, []); // Load user data on component mount

  const handleSaveUser = (user: UserData) => {
    setCookie('user', JSON.stringify(user), {
      path: '/',
      expires: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000), // Expires in 15 day
      secure: true, // Only sent over HTTPS for enhanced security (recommended)
      sameSite: 'strict', // Mitigates CSRF attacks (recommended)
    });
  };

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <SEOBasic title={APP_NAME_TAG_LINE} description={APP_DESCRIPTION} imageUrl={APP_IMAGE_URL}/>
      <PageScrollTracker log='HOME_PAGE_READ'/>
      <LandingPageAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Hero />
      <Box sx={{ bgcolor: 'background.default', mt: 5 }}>
        <Divider />
      </Box>
      <LandingPageBody />
      <Box sx={{ bgcolor: 'background.default', mb: 2 }}>
        <Divider />
      </Box>
    </ThemeProvider>
  );
}
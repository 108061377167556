import axios from "axios";
import {API_ANALYTICS_LOG, API_BASE_URL} from '../constants/CommonConstants';
import { api } from "./Axios";


export function analyticsEvent(event: string) {

    const data = {
        event: event,
        url: window.location.href
    }

    api.post( API_ANALYTICS_LOG, data)
    .then(function (response) {
      
    })
    .catch(function (error) {
      
    });
}

export function analyticsLog(event: string, log?: string) {

    const data = {
        event: event,
        log: log ? log : "",
        url: window.location.href
    }

    api.post( API_ANALYTICS_LOG, data)
    .then(function (response) {
      
    })
    .catch(function (error) {
      
    });
}